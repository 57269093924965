//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import Popup from '../../../components/Popup.vue';
import Formatter from "../../libraries/Formatter";
import { mapState } from "vuex";
import { showConfirmDialog } from "@/helpers/utils";

const formatter = new Formatter();

export default {
  name: "OrderPrinter",
  components: {Popup},
  data(){
    return {
      showMore: false,
      showFiles: false,
      logOrderPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },

      archiveFilesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      achiveOrderId: '',
      archiveLink: '-',

      openOrderLog: {
        orderId: 0,
        log: []
      }
    }
  },
  props: {
    orderData: Object
  },
  watch: {

  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedEndSum(){
      let result = +this.orderData.price;
      if(+this.orderData.use_point === 1){
        result -= +this.orderData.price_point;
      }
      if(+this.orderData.delivery === 1){
        result += +this.orderData.delivery_price;
      }
      return result;
    }
  },
  validations: {

  },
  methods: {
    getArchiveLink(orderId, type){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("type", type);

      axios
        .post(`/v1/orders/get-printer-archive`, formData)
        .then(resp => {
          this.archiveLink = resp.data.archive_path;
          this.achiveOrderId = orderId;
          this.archiveFilesPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    closeArchiveFilesPopup(){
      this.archiveFilesPopup.show = false;
    },
    refundOrder(orderId){
      showConfirmDialog(`Вы действительно хотите сделать возврат средств по заказу №${orderId}?`, { ok: 'Вернуть средства' })
          .then(() => {
            this.$store.dispatch("startPreloader");
            let formData = new FormData();
            formData.append("order_id", orderId);
            formData.append("type", 'printer');

            axios
                .post(`/v1/orders/refund`, formData)
                .then(resp => {
                  if(resp.data.result === 'ok'){
                    this.$store.dispatch("addNotification", {
                      text: resp.data.message,
                      time: 3,
                      color: "success"
                    });
                    this.$emit('updateList');
                  }else{
                    this.$store.dispatch("addNotification", {
                      text: resp.data.message,
                      time: 8,
                      color: "danger"
                    });
                  }
                  this.$store.dispatch("stopPreloader");
                })
                .catch(err => {
                  this.downloadBlock = false;
                  this.$store.dispatch("addNotification", {
                    text: err.response.data.message,
                    time: 6,
                    color: "danger"
                  });
                  this.$store.dispatch("stopPreloader");
                });
          })
    },
    setStatus(orderId, statusId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("status_id", statusId);
      formData.append("type", 'printer');

      axios
        .post(`/v1/orders/set-status`, formData)
        .then(() => {
          this.$emit('updateList');
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    dateTimeToShort(dt){
      return formatter.phpDateTimeToShortString(dt);
    },
    toggleShowMore(){
      this.showMore = !this.showMore;
    },
    toggleShowFiles(){
      this.showFiles = !this.showFiles;
    },
    buttonStatusColor(statusId){
      if(+statusId === 1){
        return 'danger';
      }else if(+statusId === 2){
        return 'warning';
      }else if(+statusId === 3){
        return 'success';
      }else if(+statusId === 4){
        return 'info';
      }else if(+statusId === 5){
        return 'info';
      }else if(+statusId === 6){
        return 'info';
      }else if(+statusId === 7){
        return 'info';
      }
    },
    closeLogOrderPopup(){
      this.logOrderPopup.show = false;
    },
    showLogOrderPopup(order_id){
      this.openOrderLog.orderId = order_id;
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", order_id);

      axios
        .post(`/v1/orders/get-log`, formData)
        .then(resp => {
          this.openOrderLog.log = resp.data;
          this.logOrderPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    printOrder(orderId) {
      showConfirmDialog(`Запустить печать заказа №${orderId}?`, { ok: 'Запустить' })
          .then(() => {
            this.$store.dispatch("startPreloader");

            let formData = new FormData();
            formData.append("order_id", orderId);
            axios
                .post(`/v1/orders/print`, formData)
                .then(response => {
                  if (response.data.result === 'ok'){
                    this.$store.dispatch("addNotification", {
                      text: response.data.message,
                      time: 3,
                      color: "success"
                    });
                    this.$emit('updateList');
                  } else {
                    this.$store.dispatch("addNotification", {
                      text: response.data.message,
                      time: 8,
                      color: "danger"
                    });
                  }
                })
                .catch(error => {
                  this.downloadBlock = false;
                  this.$store.dispatch("addNotification", {
                    text: error.response.data.message,
                    time: 6,
                    color: "danger"
                  });
                })
                .finally(() => {
                  this.$store.dispatch("stopPreloader");
                });
          })
    },
  },
  created() {
  }
};
