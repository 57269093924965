import Vue from "vue";

export function formatNumber(value, decimals = 0) {
    if (typeof value == 'string') {
        value = Number(value); // Если передана строка - пробуем её распарсить в число
    }
    if (!Number.isFinite(value)) {
        return undefined; // Некорректное число
    }

    let v = value.toFixed(decimals);
    let offset = decimals > 0
      ? 4 + decimals // сотни + точка + кол-во чисел после запятой
      : 3; // сотни
    for (let i = v.length - offset; i > 0; i -= 3) {
        v = v.slice(0, i) + ' ' + v.slice(i)
    }
    return v;
}

export function formatMoney(value) {
    return formatNumber(value, 2);
}

export function showConfirmDialog(message, { ok, cancel } = {}) {
    return Vue.prototype.$showConfirmDialog(message, ok, cancel);
}
