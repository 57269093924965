//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import Formatter from '../../../components/libraries/Formatter';

const formatter = new Formatter();

import axios from "axios";
import Popup from '../../../components/Popup.vue';
import { mapState } from "vuex";
import OrderPrinter from '../../../components/management/orders/OrderPrinter.vue';
import OrderService from '../../../components/management/orders/OrderService.vue';
import Paginator from '../../../components/Paginator.vue';
import CardOrderService from '../../../components/management/orders/CardOrderService.vue';


/**
 * Printers component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    Multiselect,
    //Paginator,
    Popup,
    OrderPrinter,
    OrderService,
    Paginator,
    CardOrderService
},
  data() {
    return {
      queryParams: this.$route.query,
      title: this.$t('views.orders.ordersAnalytics'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.orders'),
          active: true
        }
      ],
      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
           months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },
      daterange: [],
      dateConvert: [],
      filterType: '',
      filterStatus: '',
      officeSearch: null,
      blockFilterWatch: false,
      searchStrTimeout: false,
      downloadBlock: false,
      ignoreDate: false,
      thisPage: 1, // Текущая страница
      pagesCountPaginator: 0, // Кол-во страниц
      pageSize: 25, // Сколько записей подгружать на 1 страницу
      orders: [],
      logOrderPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      orderCardPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      openOrderLog: {
        orderId: 0,
        log: []
      },

      service_types: [],
      options: [],
      searchString: "",
      isLoading: false,
      statuses: [],

      cardOrder: {}
    };
  },
  validations: {

  },
  watch: {
    daterange: function (val) {
      let result = [];
      if(val[0] === null){
        this.dateConvert = [];
        return true;
      }
      if (val.length) {
        result[0] = formatter.jsObjectToPhp(val[0]);
        result[1] = formatter.jsObjectToPhp(val[1]);
      }
      this.dateConvert = result;
    },
    dateConvert: function () {
      if(!this.blockFilterWatch){
        this.setPage(1); // reset page on apply filters
        this.loadList();
      }
    },
    filterType: function () {
      if(!this.blockFilterWatch){
        this.setPage(1); // reset page on apply filters
        this.loadList();
      }
    },
    filterStatus: function () {
      if(!this.blockFilterWatch){
        this.setPage(1); // reset page on apply filters
        this.loadList();
      }
    },
    officeSearch: function () {
      if(!this.blockFilterWatch){
        this.setPage(1); // reset page on apply filters
        this.loadList();
      }
    },
    searchString: function() {
      clearTimeout(this.searchStrTimeout);
      if(!this.blockFilterWatch){
        this.searchStrTimeout = setTimeout(() => {
          this.setPage(1); // reset page on apply filters
          this.loadList();
        }, 2000);
      }
    },
  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedCardOrder() {
      return Array.isArray(this.cardOrder) ? {} : this.cardOrder;
    }
  },
  methods: {
    closeOrderCardPopup() {
      this.orderCardPopup.show = false;
    },
    showOrderCardPopup() {
      this.orderCardPopup.show = true;
      this.getCardOrder(this.queryParams.type, this.queryParams.id);
    },
    updateOrderCard() {
      this.getCardOrder(this.queryParams.type, this.queryParams.id);
      this.loadList();
    },
    getCardOrder (type, orderId) {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("type", type);
      formData.append("id", orderId);
      axios
        .post(`/v1/orders/get-one`, formData)
        .then(resp => {
          this.cardOrder = resp.data;
          this.$store.dispatch("startPreloader");
        })
        .catch(err => {
          //this.isLoading = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("startPreloader");
        });
    },
    nameWithAddress({ name, address }) {
      let text = `${name} - ${address}`;
      return text;
    },
    async clearAllFilter(){
      this.blockFilterWatch = true;
      this.$set(this.daterange, 0, null);
      this.$set(this.daterange, 1, null);
      this.dateConvert = [];
      this.filterType = '';
      this.filterStatus = '';
      this.officeSearch = null;
      this.searchString = '';
      await this.loadList().then(() => {
        this.blockFilterWatch = false;
      });
    },

    officeFind(query) {
      if (query.length) {
        // this.searchString = query;
        this.isLoading = true;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          let formData = new FormData();
          formData.append("query", query);
          axios
            .post(`/v1/orders/search-office`, formData)
            .then(resp => {
              this.options = resp.data;
              this.isLoading = false;
            })
            .catch(err => {
              this.isLoading = false;
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
        }, 800);
      }
    },
    selectOffice(val) {
      this.options = [];
      this.options.push(val);
      this.officeSearch = val;
    },
    resetSelectOffice() {
      this.officeSearch = null;
    },
    closeLogOrderPopup(){
      this.logOrderPopup.show = false;
    },
    setPage(val) {
      this.thisPage = val;
      this.loadList();
    },
    async loadList(){
      this.downloadBlock = true;
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      if(this.dateConvert.length){
        formData.append("filter[date_from]", this.dateConvert[0]);
        formData.append("filter[date_to]", this.dateConvert[1]);
      }
      if(this.filterType !== ''){
        formData.append("filter[type]", this.filterType);
      }
      if(this.filterStatus !== ''){
        formData.append("filter[status]", this.filterStatus);
      }
      if(this.officeSearch){
        formData.append("filter[office_type]", this.officeSearch.type);

        if(this.officeSearch.type === 'printer'){
          formData.append("filter[office_id]", this.officeSearch.serial_number);
        }else{
          formData.append("filter[office_id]", this.officeSearch.id);
        }
      }
      if(this.searchString !== ''){
        formData.append("filter[search_str]", this.searchString);
      }

      formData.append("page", this.thisPage);
      formData.append("page_size", this.pageSize);

      await axios
        .post(`/v1/orders/get-list`, formData)
        .then(resp => {
          this.downloadBlock = false;
          this.pagesCountPaginator = resp.data.pages;
          this.orders = resp.data.data;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    getStatuses(){
      axios
        .get(`/v1/orders/get-statuses`)
        .then(resp => {
          this.statuses = resp.data;
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    getServiceTypes(){
      axios
        .get(`/v1/orders/get-service-types`)
        .then(resp => {
          this.service_types = resp.data;
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    }
  },
  created() {
    this.getStatuses();
    this.getServiceTypes();
    this.loadList();

    if (Object.keys(this.queryParams).length > 0) {
      this.showOrderCardPopup();
    }
  }
};
