//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//




import axios from "axios";
//import SimpleSelectCheckbox from "@/components/SimpleSelectCheckbox";
import Formatter from "../../libraries/Formatter";
import { mapState } from "vuex";
import Popup from '../../Popup.vue';

const formatter = new Formatter();

export default {
  name: "CardOrderService",
  components: {Popup},
  data() {
    return {
      archiveFilesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      achiveOrderId: '',
      archiveLink: '-',
      showFiles: false,

      documentPhotoGridPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      documentPhotoGridOrderId: '',
      documentPhotoGridLink: '',

      setStatusForce: false,
      pinPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      setStatusId: 0,
      setStatusOrderId: 0,
      pin: '',

      dynamicFields: {
        1: [
          {paper: "Бумага"},
          {format: "Формат"},
          {borders: "С полями"},
        ]
      }
    }
  },
  props: {
    orderData: Object
  },
  watch: {
    
  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedEndSum(){
      let result = +this.orderData.price;
      if(+this.orderData.delivery === 1){
        result += +this.orderData.delivery_price;
      }
      if(+this.orderData.use_point === 1){
        result += +this.orderData.price_point;
      }
      return result;
    }
  },
  validations: {
    
  },
  methods: {
    closeDocumentPhotoGridPopup(){
      this.documentPhotoGridPopup.show = false;
    },
    getDocumentPhotoGrid(orderId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      
      axios
        .post(`/v1/grid/document-photo`, formData)
        .then(resp => {
          this.documentPhotoGridLink = resp.data.download_link;
          this.documentPhotoGridOrderId = orderId;
          this.documentPhotoGridPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    getArchiveLink(orderId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      
      axios
        .post(`/v1/orders/get-service-archive`, formData)
        .then(resp => {
          this.archiveLink = resp.data.archive_path;
          this.achiveOrderId = orderId;
          this.archiveFilesPopup.show = true;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    closeArchiveFilesPopup(){
      this.archiveFilesPopup.show = false;
    },
    refundOrder(orderId){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("type", 'service');
      
      axios
        .post(`/v1/orders/refund`, formData)
        .then(resp => {
          if(resp.data.result == 'ok'){
            this.$store.dispatch("addNotification", {
              text: resp.data.message,
              time: 3,
              color: "success"
            });
            this.$emit('updateList');
          }else{
            this.$store.dispatch("addNotification", {
              text: resp.data.message,
              time: 8,
              color: "danger"
            });
          }
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    closePinPopup(){
      this.pinPopup.show = false;
      this.pin = '';
    },
    showPinPopup(){
      this.pinPopup.show = true;
    },
    pinPopupSubmit(){
      this.setStatusForce = true;
      this.setStatus(
        this.setStatusOrderId, 
        this.setStatusId,
        0,
        'done'
      );
    },
    setStatus(orderId, statusId, isDelivery, codename){
      if(
        codename === 'done' && 
        isDelivery === 0 && 
        !this.setStatusForce && 
        !this.userPermissions.includes('completed-orders-force')
      ){
        this.setStatusOrderId = orderId;
        this.setStatusId = statusId;
        this.showPinPopup();
        return false;
      }
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("order_id", orderId);
      formData.append("status_id", statusId);
      formData.append("type", 'service');
      formData.append("pin", this.pin);
      formData.append("codename", codename);

      axios
        .post(`/v1/orders/set-status`, formData)
        .then(() => {
          this.$emit('updateOrder');
          this.setStatusForce = false;
          this.closePinPopup();
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    dateTimeToShort(dt){
      return formatter.phpDateTimeToShortString(dt);
    },
    toggleShowFiles(){
      this.showFiles = !this.showFiles;
    },
    buttonStatusColor(statusId){
      if(+statusId === 1){
        return 'danger';
      }else if(+statusId === 2){
        return 'warning';
      }else if(+statusId === 3){
        return 'info';
      }else if(+statusId === 4){
        return 'success';
      }else if(+statusId === 5){
        return 'info';
      }else if(+statusId === 6){
        return 'info';
      }else if(+statusId === 7){
        return 'info';
      }
    },
  },
  created() {
    
  }

};
